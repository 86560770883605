class HelpScout {
  static hasBeacon = false;

  static init = (mapping, institution, role) => {
    const selectedBeacon = mapping?.[institution]?.[role] ?? mapping.promptly?.[role];

    if (!selectedBeacon) {
      return;
    }

    this.hasBeacon = true;
    window.Beacon('init', selectedBeacon);
    window.Beacon('config', {
      display: {
        style: 'manual'
      }
    });
  };

  static process = ({ func, url, email, newEmail }) => {
    if (url === '' || !url || email === newEmail) {
      return;
    }
    func(url, { email: newEmail });
  };

  static identify = ({ user, role, institution, signatures }) => {
    let signature;
    if (!Object.keys(signatures).length) {
      signature = undefined;
    } else {
      signature = signatures[institution] ? signatures[institution][role] : signatures.promptly[role];
    }
    const data = {
      name: user.private_data.name,
      ...(user.email && { email: user.email }),
      phone: user.phone,
      ...(user.private_data.avatar_url ? { avatar: user.private_data.avatar_url } : {}),
      ...(signature && { signature })
    };
    window.Beacon('identify', data);
  };

  static open = () => {
    window.Beacon('open');
  };

  static destroy = () => {
    this.hasBeacon = false;
    window.Beacon('destroy');
  };

  static logout = () => {
    this.hasBeacon = false;
    window.Beacon('logout', { endActiveChat: true });
  };
}

export default HelpScout;
